<template>
	<div class="page-upload-license mobile-container">
		<div class="text-box">
			<div class="title">上传营业执照</div>
			<div class="description">请上传清晰营业执照彩色照片，复印件需要加盖公司红章。</div>
		</div>
		<div class="default-box" v-if="fileList.length == 0" @click="handleUploaderClick">
			<div class="cf">
				<div class="item fl">
					<div class="label">营业执照面</div>
					<div class="text">上传营业执照面</div>
				</div>
				<div class="item fr">
					<van-image width="5rem" height="3.36rem" :src="licenseImg.default" />
				</div>
			</div>
		</div>
		<div class="upload-box">
			<van-uploader ref="licenseUploader" v-model="fileList" multiple accept="image/*" :max-count="1" :after-read="afterRead"
				:before-delete="deleteImg" />
		</div>
		<div class="demo-box">
			<van-divider>上传证件要求</van-divider>
			<van-image :src="licenseImg.demo" />
			<div class="text">您提供的信息将予以加密保护，保证此证件照片仅用于国美家办理业务。</div>
		</div>
		<div class="submit-box">
			<div class="button" @click="save">提交</div>
		</div>
	</div>
</template>

<script>
require("./assets/utils/px2rem");
/**
 * 单文件按需引入vant组件
 */
import "vant/lib/toast/style";
import "vant/lib/uploader/style";
import "vant/lib/image/style";
import "vant/lib/divider/style";
import {
	Uploader as VanUploader,
	Image as VanImage,
	Divider as VanDivider,
	Toast
} from "vant";
import axios from "axios";
import { sumbitOcrSrc } from "@/api/api-join-mall";
export default {
	components: {
		VanUploader,
		VanImage,
		VanDivider,
	},
	computed: {
		uploadUrl() {
			return `/${
				process.env.VUE_APP_MODE === "production" ? "api-match" : "api"
			}/gm-nxcloud-resource/api/nxcloud/res/upload`;
		},
	},
	data() {
		return {
			licenseImg: {
				default: require("./assets/img/license.png"),
				demo: require("./assets/img/license_demo.png"),
			},
			fileList: [],
			imgList: [],
			files: {
				name: "",
				type: "",
			},
			submitPendingFlag: false,
			fileLen: 1,
		};
	},
	methods: {
		// dispatch上传组件
		handleUploaderClick() {
			let $el = document.getElementsByClassName('van-uploader__input')[0];
			$el.dispatchEvent(new MouseEvent('click'));
		},
		afterRead(file) {
			this.fileLen = 1;
			if (Array.isArray(file)) {
				this.fileLen = file.length;
				file.map((item, index) => {
					this.uploadPicture(
						item.file,
						this.imgList.length + index,
						false
					);
				});
			} else {
				this.uploadPicture(file.file, this.imgList.length, true);
			}
		},
		uploadPicture(file, index, value) {
			// Toast是怎么注册进来的，比较奇怪
			let loading = Toast.loading({
				duration: 0,
				forbidClick: true,
				message: "上传中...",
			});

			let data = new FormData();
			data.append("file", file);
			data.append("fileType", 4);
			// 封装的axios对url拦截，并强制修改了content-type，造成类型错误
			axios({
				url: this.uploadUrl,
				method: "post",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				data,
			})
				.then((res) => {
					if (res.data.code === 0) {
						if (value) {
							this.imgList.push(res.data.data.res.id);
						} else {
							this.$set(
								this.imgList,
								index,
								res.data.data.res.id
							);
						}
					} else {
						console.warn("--上传出错--", "第" + (index + 1) + "张");
					}
					this.fileLen--;
					if (this.fileLen == 0) loading.clear();
				})
				.catch((err) => {
					loading.clear();
					console.log(err);
				});
		},
		deleteImg(file, name) {
			let sub = name.index;
			//imgList
			this.imgList.splice(sub, 1);
			//fileList是展示预览的图片数组
			this.fileList.splice(sub, 1);
		},
		save() {
			if (this.submitPendingFlag) {
				console.warn("已拦截重复提交.");
				return;
			}
			if (this.imgList.length == 0) {
				Toast({ message: "请先选择图片" });
				return;
			}
			if (this.fileLen > 0) {
				console.warn("上传中，请稍后...");
				return;
			}
			let loading = Toast.loading({
				duration: 0,
				forbidClick: true,
				message: "提交中...",
			});
			this.submitPendingFlag = true;
			sumbitOcrSrc({
				userId: this.$route.query.id,
				type: 1, // 1-营业执照 2-身份证正面 3-身份证反面
				resId: this.imgList.join(',')
			})
				.then((res) => {
					if (res.data.code == 0) {
						Toast.success("提交成功");
					} else {
						Toast({ message: res.data.message });
					}
					this.submitPendingFlag = false;
				})
				.catch(() => {
					loading.clear();
					this.submitPendingFlag = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./assets/styles/mobile.scss";
/deep/ {
	.van-uploader__upload {
		visibility: hidden;
		position: absolute;
		bottom: px2rem(-100);
		left: 0;
		width: px2rem(76);
		height: px2rem(76);
		border: 1px solid #999997;
		border-radius: px2rem(6);
		background-color: #f3f3f3;
		&::after {
			position: absolute;
			bottom: 10px;
			margin: 0 auto;
			content: "最多5张";
			font-size: 12px;
			color: #666666;
			pointer-events: none;
		}
		.van-icon-photograph {
			margin-top: -10px;
			&::before {
				content: "";
				width: px2rem(25);
				height: px2rem(25);
				background-image: url("./assets/img/upload_icon.png");
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}
		}
	}
}
/** 页面容器 */
.page-upload-license {
	font-size: px2rem(14);
	background-color: #fff;
	.text-box {
		padding: px2rem(25) px2rem(20) px2rem(19) px2rem(27.5);
		.title {
			font-size: px2rem(20);
			font-weight: 500;
		}
		.description {
			font-size: px2rem(15);
			color: #333333;
			margin-top: px2rem(15);
		}
	}
	.default-box {
		margin: 0 px2rem(20) 0;
		padding: px2rem(11) px2rem(11) 0;
		background-color: #f5f6f9;
		text-align: center;
		.cf {
			.item {
				display: inline-block;
				color: #333333;
				vertical-align: top;
				&.fl {
					margin-top: px2rem(15);
				}
				.label {
					font-size: px2rem(20);
					font-weight: 500;
					margin-bottom: px2rem(5);
				}
				.text {
					font-size: px2rem(12);
					text-align: left;
				}
			}
		}
	}
	.upload-box {
		margin: 0 px2rem(20) px2rem(100);
		padding: px2rem(11) px2rem(11) 0;
	}
	.demo-box {
		margin-bottom: px2rem(100);
		.text {
			padding: px2rem(10) px2rem(27);
			font-size: 12px;
			color: #999999;
		}
	}
	.submit-box {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		padding-top: px2rem(10);
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: $safe-width;
		background-color: #fff;
		.button {
			font-size: px2rem(18);
			font-weight: 500;
			letter-spacing: 2px;
			color: #fff;
			width: 76%;
			padding: px2rem(10);
			margin-bottom: px2rem(10);
			border-radius: px2rem(6);
			text-align: center;
			background-color: $color-theme;
		}
	}
}
</style>